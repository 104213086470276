<template>
  <layout :title="$route.name" :options="options">
    <div class="expande-horizontal wrap">
      <v-flex xs12>
        <SendArchive />
      </v-flex>
      <v-flex xs12>
        <v-form ref="form">
          <v-flex xs12>
            <div class="expande-horizontal wrap">
              <v-flex xs12 md3 class="px-3">
                <span class="fonte orange--text"> Descrição </span>
                <v-text-field
                  solo-inverted
                  dark
                  dense
                  label="Descricao"
                  v-model="getCareer.description"
                ></v-text-field>
              </v-flex>
  
              <v-flex xs12 md3 class="px-3">
                <span class="fonte orange--text"> Preço </span>
                <v-text-field
                  solo-inverted
                  dark
                  dense
                  label="Descricao"
                  prefix="$"
                  v-mask="['#', '##', '###', '####', '#####', '######']"
                  v-model="getCareer.price"
                ></v-text-field>
              </v-flex>
              
              <v-flex xs12 md3 class="px-3">
                <span class="fonte orange--text"> Mensalidade </span>
                <v-text-field
                  solo-inverted
                  dark
                  dense
                  label="Mensalidade"
                  prefix="$"
                  v-mask="['#', '##', '###', '####', '#####', '######']"
                  v-model="getCareer.fee"
                ></v-text-field>
              </v-flex>
  
              <v-flex xs12 md3 class="px-3">
                <span class="fonte orange--text"> Bônus binário anexado </span>
                <v-text-field
                  solo-inverted
                  dark
                  prefix="%"
                  type="Number"
                  dense
                  label="Bônus anexado"
                  v-model="getCareer.percent_binary_append"
                ></v-text-field>
              </v-flex>
  
              <!-- <v-flex xs12 class="pa-2">
                <h3 class="fonte orange--text">
                  Benefícios do plano de carreira
                </h3>
                <v-select
                  :items="getSystemActions"
                  v-model="getCareer.system_actions"
                  item-text="action"
                  return-object
                  filled
                  outlined
                  dark
                  multiple
                  label="Benefícios do plano"
                ></v-select>
              </v-flex> -->
            </div>
          </v-flex>
        </v-form>
      </v-flex>

      <!-- <v-flex xs12 class="pa-2">
        <h3 class="fonte orange--text">Listagem de Benefícios</h3>
        <v-list dense class="pa-0 ma-0">
          <template v-for="(item, i) in getCareer.system_actions">
            <v-list-item :key="item.action">
              <v-list-item-content>
                <v-list-item-title> {{ item.description }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="i"></v-divider>
          </template>
        </v-list>
      </v-flex> -->
    </div>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SendArchive from "@/apps/shared/components/ModalSendArchive.vue";

export default {
  components: {
    SendArchive
  },
  props: ["id"],
  data() {
    return {
      options: [
        {
          nome: "Salvar Plano",
          action: () => this.validaForm()
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["getCareer", "getSystemActions"])
  },
  methods: {
    ...mapActions([
      "viewCareer",
      "updateCareer",
      "createCareer",
      "listSystemActions"
    ]),
    validaForm() {
      if (this.$refs.form.validate()) {
        if (this.getCareer._id) {
          this.updateCareer();
        } else {
          this.createCareer();
        }
      }
    }
  },
  created() {
    if (this.id) {
      this.viewCareer({ _id: this.id });
    }

    this.listSystemActions();
  }
};
</script>
